/*
	Twenty by HTML5 UP
	html5up.net | @n33co
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Section/Article */

	section > .last-child, section.last-child,
	article > .last-child,
	article.last-child {
		margin-bottom: 0;
	}

/* Banner */

	#banner {
		background-image: url("../images/banner.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: auto;
	}

		#banner .inner {
			background: #341b2b;
		}

/* CTA */

	#cta {
		background-image: url("../images/banner.jpg");
		background-repeat: no-repeat;
		background-size: cover;
		background-position: auto;
	}
